<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-form v-model="valid"></v-form>
      <div v-if="isItemModified"></div>
      <div v-if="formMode"></div>
      <v-card>
        <v-form v-model="valid" ref="form">

          <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>

            <v-container>
<!--              <v-tabs>-->
<!--                <v-tab>Deskripsi</v-tab>-->
<!--                <v-tab>Sambung</v-tab>-->
<!--                <v-tab>Log</v-tab>-->
<!--                <v-tab-item>-->
<!--                  <FSiswaGeneral-->
<!--                      :itemModified="itemModified"-->
<!--                      :itemsFDivision="itemsFDivision"-->

<!--                  ></FSiswaGeneral>-->
<!--                </v-tab-item>-->
<!--                <v-tab-item>-->
<!--                  <FSiswaTax-->
<!--                      :itemModified="itemModified"-->
<!--                      :itemsFDivision="itemsFDivision"-->
<!--                  >-->
<!--                  </FSiswaTax>-->
<!--                </v-tab-item>-->

<!--              </v-tabs>-->

                  <FSiswaGeneral
                      :itemModified="itemModified"
                      :itemsFDivision="itemsFDivision"

                  ></FSiswaGeneral>
                <FSiswaTax
                    v-show="false"
                    :itemModified="itemModified"
                    :itemsFDivision="itemsFDivision"
                >
                </FSiswaTax>

            </v-container>

          </v-card-text>

          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

    </v-dialog>

  </div>

</template>

<script>
import FSiswaService from '../../../services/apiservices/f-siswa-service';
import FDivision from "../../../models/f-division";

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FSiswa from "../../../models/f-siswa";
import { format, parseISO } from 'date-fns'
import FSiswaGeneral from "./FSiswaGeneral"
import FSiswaTax from "./FSiswaTax";

export default {
  components: {
    FSiswaTax, FSiswaGeneral, CloseConfirmDialog
  },
  props:{
    formMode: String,

  },
  data() {
    return {
      title: 'Siswa-Santri',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },
      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,


      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FSiswa(0, '', ''),
      selectFDivision: '',
      itemsFDivision: [
          new FDivision()
      ],

      itemsFile:[],


      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

  },

  watch: {
  },

  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    showDialog(selectedIndex, item, itemsFDivision){
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FSiswa(),
        this.itemModified =  new FSiswa(),
        this.selectedIndex = -1
      }

      this.itemsFDivision = itemsFDivision

      // this.itemsFSiswaSalesBrand = itemsFSiswaSalesBrand
      // this.itemsFPegawai = itemsFPegawai

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(andCloseDialog){
      // console.log(andCloseDialog)
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }

      this.itemModified.modifiedBy = this.$store.state.auth.user.username;
      this.itemModified.modified = new Date();

      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {

          // console.log(`cek sebelum update >> ${this.itemModified.id}`)
          FSiswaService.updateFSiswa(this.itemModified).then(
              () => {
                // console.log(response.data)
                if (andCloseDialog){
                  this.$emit('eventFromFormDialogEdit', this.itemModified)
                }
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {

          if (this.itemModified.fdivisionBean >0 && this.itemModified.vcode !==undefined &&
              this.itemModified.vname !==undefined){

            FSiswaService.createFSiswa(this.itemModified).then(
                response =>{
                  if (andCloseDialog){
                    this.$emit('eventFromFormDialogNew', response.data)
                  }
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }
        }//endif
      }//endif validate

    },

    saveCreateOnly(){
      FSiswaService.createFSiswa(this.itemModified).then(
          response =>{
              /**
               * dipaksa Save dan Update Dahulu
               */
              // this.initializeEditMode(response.data)
            this.$emit('update:formMode', FormMode.EDIT_FORM)
            this.itemModified.id = response.data.id
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FSiswaService.getFSiswaById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )

    },
    retrieveFiles(){
      // FSiswaFileService.getAllFSiswaFileByParent(this.itemModified.id).then(
      //     response=>{
      //       this.itemsFtSalesdItems = response.data
      //     },
      //     error=>{
      //       console.log(error.response)
      //     }
      // )

    },


  }

}
</script>

<style scoped>
</style>
<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FSiswaTable/>
  </v-card>
</template>

<script>
import FSiswaTable from "../../components/akademis/siswa/FSiswaTable";

export default {
  components: { FSiswaTable },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
</style>
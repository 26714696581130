<template>

  <v-container>

    <v-row >

      <v-col
          cols="12"
          sm="4"
          md="4"
      >

        <v-container class="pa-4 text-center">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <template>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >

                <v-flex class="d-flex flex-wrap">
                  <v-hover v-slot="{ hover }">
                    <v-card
                        :elevation="hover ? 10 : 0"
                        :class="[{ 'on-hover': hover }, hover?'card-hover-opacity':'card-not-hover-opacity']"
                    >
                      <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          max-width="250px"
                          max-height="250px"
                          contain
                      >
                        <!--                              max-height="300px"-->
                        <v-card-title class="text-h6 white--text fill-height">

                          <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                          >
                            <v-spacer/>
                            <div class="align-self-center">
                              <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                              >
                                <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                >
                                  mdi-upload
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-row>

                        </v-card-title>
                      </v-img>

                    </v-card>
                  </v-hover>
                </v-flex>

              </v-col>
            </template>
          </v-row>
        </v-container>

      </v-col>

      <v-col
          cols="12"
          sm="8"
          md="8"
      >

        <v-row>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-text-field
                v-model="itemModified.kode1"
                :rules="rulesLenght"
                label="Kode"
                dense
                hide-details
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.statusActive"
                :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                x-small
                dense
            ></v-switch>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              cols="12"
              sm="6"
              md="6"
          >
            <v-text-field
                v-model="itemModified.fullName"
                label="Nama"
                dense
                :rules="rulesLenght"
            ></v-text-field>

          </v-col>
          <v-col
              cols="12"
              sm="2"
              md="2"
          >
            <v-switch
                v-model="itemModified.sex"
                :label="itemModified.sex?'Laki-Laki':'Perempuan'"
                x-small
                dense
            ></v-switch>
          </v-col>
        </v-row>
        <v-col
            cols="12"
            sm="2"
            md="2"
        >
          <v-switch
              v-model="itemModified.menikah"
              :label="itemModified.menikah?'Menikah':'Belum'"
              x-small
              dense
          ></v-switch>
        </v-col>

        <v-row>
        </v-row>

      </v-col>

    </v-row>



    <v-row>
      <v-col
          cols="12"
          sm="5"
          md="5"
      >
        <v-autocomplete
            v-model="itemModified.fdivisionBean"
            :items="itemsFDivision"
            :rules="rulesNotEmtpy"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            chips
            deletable-chips
            color="blue-grey lighten-2"
            label="Unit"
            :hint="`Division = Unit : ${lookupFDivision(itemModified.fdivisionBean)}`"
            persistent-hint
            small-chips
        ></v-autocomplete>

      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="6"
      >
        <v-text-field
            v-model="itemModified.address1"
            label="Alamat"
            dense
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.address2"
            label="Kecamatan"
            dense
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="3"
          md="3"
      >
        <v-text-field
            v-model="itemModified.city"
            label="Kota"
            dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.phone"
            label="Phone"
            type="number"
            prepend-inner-icon="mdi-phone"
            :prefix="`+62`"
            dense
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="4"
      >
        <v-text-field
            v-model="itemModified.email"
            label="Email"
            prepend-inner-icon="mdi-email"
            dense
        ></v-text-field>
      </v-col>
    </v-row>

    <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FSiswa from "../../../models/f-siswa";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import FSiswaService from "../../../services/apiservices/f-siswa-service"
import FormMode from "../../../models/form-mode";

export default {
  components:{
    UploadImageDialog
  },
  props:{
    itemModified: new FSiswa(),

    itemsFDivision: [],

  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,


      selectedItemIndex: -1,
      itemDefault: '',
      // itemModified: new FSiswa(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      // itemsFDivision: [
      //   new FDivision()
      // ],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  methods:{
    showDialogUpload(){
      if (this.itemModified.kode1 !==undefined &&
          this.itemModified.fullName !==undefined &&
        this.itemModified.fdivisionBean !==undefined){

        if (this.itemModified.id===0){
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly()
        }
        this.$refs.refUploadDialog.showDialog()

      }else{
        this.snackBarMesage = 'Pcode, Pname dan Divisi harus diisi dahulu'
        this.snackbar = true
      }

    },
    completeUploadSuccess: function (val){

      // console.log(val)
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)
      if (this.itemModified.avatarImage !==undefined && this.itemModified.avatarImage !== '' ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
            response => {
              console.log(response.data)
            },
            error => {
              console.log(error.response)
            }
        )
      }

      if (val.fileName !==""){
        this.$refs.refUploadDialog.closeDialog()
        this.itemModified.avatarImage = val.fileName
        this.saveUpdateOnly()
      }

    },
    saveCreateOnly(){
      FSiswaService.createFSiswa(this.itemModified).then(
          response =>{
            /**
             * dipaksa Save dan Update Dahulu
             */
            this.initializeEditMode(response.data)
            this.formMode = FormMode.EDIT_FORM
          },
          error => {
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    saveUpdateOnly(){
      FSiswaService.updateFSiswa(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_medium(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  }

}

</script>


<style scoped>
.card-hover-opacity{
  opacity: 0.4;
}
.card-not-hover-opacity{
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>
